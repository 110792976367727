import React from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import {
    OPEN_GRAPH_IMAGE_PATH,
    SOCIAL_MEDIA_LINKS,
} from "../../config/content.js";
import { MAP_MARKER } from "../../config/icons.js";
import {
    GetContactsContentData,
    GET_CONTACTS_CONTENT,
} from "../../graphql/queries/contacts.js";
import { ContactsPageContent } from "../../i18n/content.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import ErrorMsg from "../ErrorMsg.js";
import MetaTags from "../MetaTags.js";
import SocialMediaLink from "../SocialMediaLink.js";
import Spinner from "../Spinner.js";
import Icon from "../svg/Icon.js";
import { commonPageTitle, container } from "../styles/common.css.js";
import {
    addressText,
    locationItem,
    locationItemsWrapper,
    socialLinks,
} from "./Contacts.css.js";

interface Props {
    path: string;
}
const ContactUs: React.FC<Props> = ({ path }) => {
    const { data, loading, error } =
        useQuery<GetContactsContentData<ContactsPageContent>>(
            GET_CONTACTS_CONTENT,
        );
    if (error) {
        return <ErrorMsg type={"default"} />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (data && data.staticContent) {
        const {
            content: {
                title,
                metaTitle,
                metaDescription,
                _text,
                locationTitle,
                socialTitle,
                _addressOne,
                _addressTwo,
            },
        } = data.staticContent;
        const locationItems = [_addressOne, _addressTwo];

        return (
            <>
                <MetaTags
                    path={path}
                    title={metaTitle || title}
                    description={metaDescription}
                    openGraphImage={[OPEN_GRAPH_IMAGE_PATH, "1200x630"]}
                />
                <div className={container.flex800}>
                    <h1 className={commonPageTitle}>{title}</h1>
                    <MarkdownRenderer source={_text} />
                    <h2>{locationTitle}</h2>
                    <div className={locationItemsWrapper}>
                        {locationItems.map(item => (
                            <div className={locationItem} key={item}>
                                <Icon
                                    path={MAP_MARKER}
                                    width="25px"
                                    height="25px"
                                />
                                <MarkdownRenderer
                                    className={addressText}
                                    source={item}
                                />
                            </div>
                        ))}
                    </div>
                    <h2>{socialTitle}</h2>
                    <div className={socialLinks}>
                        {SOCIAL_MEDIA_LINKS.map(({ iconKey, link }) => (
                            <SocialMediaLink
                                key={iconKey}
                                iconKey={iconKey}
                                link={link}
                            />
                        ))}
                    </div>
                </div>
            </>
        );
    }
    return <ErrorMsg type={"notFound"} />;
};

export default ContactUs;
