import gql from "graphql-tag";

import { HomePageContent } from "../../i18n/content.js";
import { NewsContent, News } from "./news.js";
import { FeedConnection } from "./_common.js";
import { ImagePreview, Image, MediaItemPreview } from "./gallery.js";
import { AppIcon } from "./app.js";

export interface GetHomeContentVariables {
    first: number;
}

type NewsContentForHome = Pick<NewsContent, "title">;

export type NewsItemForHome = Pick<
    News<NewsContentForHome, ImagePreview>,
    "id" | "content" | "date" | "featured" | "image"
>;
export interface FeaturedProduct {
    id: string;
    homeSliderImage: Image;
}

interface PromoApp {
    id: string;
    icon: AppIcon | null;
}
export interface Partner {
    id: string;
    content: {
        langCode: string;
        name: string;
    };
    url: string;
    imageUrl: string;
    rel?: string;
}
export interface GetHomeContentData {
    homePage: {
        id: string;
        content: HomePageContent;
        mediaItems: (MediaItemPreview | null)[] | null;
    } | null;
    companyNewsFeed: Pick<FeedConnection<NewsItemForHome>, "edges"> | null;
    featuredProducts: FeaturedProduct[];
    app: {
        promoApps: PromoApp[];
    } | null;
    partners: Partner[] | null;
}
export const GET_HOME_CONTENT = gql`
    query getHomeContent($first: Int) {
        homePage: staticContent(id: "vt-site-home") {
            id
            content
            mediaItems {
                id
                content {
                    name
                    sourceUrl
                    ... on ImageContent {
                        thumbnails {
                            fileName
                            width
                            height
                        }
                    }
                }
            }
        }
        companyNewsFeed(first: $first) {
            edges {
                node {
                    id
                    date
                    featured
                    content {
                        title
                    }
                    image {
                        id
                        content {
                            name
                            sourceUrl
                            thumbnails {
                                fileName
                                width
                                height
                            }
                        }
                    }
                }
            }
        }
        featuredProducts {
            id
            homeSliderImage {
                id
                content {
                    name
                    description
                    sourceUrl
                    thumbnails {
                        fileName
                        width
                        height
                    }
                }
            }
        }
        app {
            id
            promoApps {
                id
                icon {
                    sizes
                    sourceUrl
                }
            }
        }
        partners {
            id
            content {
                name
            }
            url
            imageUrl
        }
    }
`;
