import gql from "graphql-tag";
import { FeedConnection } from "./_common.js";
export interface SitemapStaticData {
    staticContent: {
        id: string;
        content: {
            title: string;
            metaTitle: string;
            metaDescription: string;
        };
    };
}
export interface SitemapLinkData {
    id: string;
    content: {
        title: string;
    };
}
export interface SitemapListData {
    feed: FeedConnection<SitemapLinkData>;
}
export interface GetSitemapVariables {
    first: number;
    after?: string;
}
export const GET_SITEMAP_STATIC_CONTENT = gql`
    query getSitemapStaticContent {
        staticContent(id: "vt-site-sitemap") {
            content
        }
    }
`;
export const GET_NEWS_FEED_FOR_SITEMAP = gql`
    query getNewsFeedForSitemap($first: Int, $after: CompanyNewsCursor) {
        feed: companyNewsFeed(first: $first, after: $after) {
            totalCount
            offset
            edges {
                cursor
                node {
                    id
                    content {
                        title
                    }
                }
            }
        }
    }
`;
export const GET_PRODUCTS_FEED_FOR_SITEMAP = gql`
    query getProductsFeedForSitemap {
        feed: products {
            edges {
                cursor
                node {
                    id
                    content {
                        title: name
                    }
                }
            }
        }
    }
`;
