import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { OPEN_GRAPH_IMAGE_PATH } from "../../config/content.js";
import {
    GET_NEWS_FEED_FOR_SITEMAP,
    GET_PRODUCTS_FEED_FOR_SITEMAP,
    GET_SITEMAP_STATIC_CONTENT,
    SitemapStaticData,
} from "../../graphql/queries/sitemap.js";
import { gaSitemapClick } from "../../helpers/googleAnalytics.js";
import Content from "../../i18n/content.js";
import { commonPageTitle, container } from "../styles/common.css.js";
import ErrorMsg from "../ErrorMsg.js";
import MetaTags from "../MetaTags.js";
import Spinner from "../Spinner.js";
import LinksListLoader from "./LinksListLoader.js";
import { itemHeader, list, listItem } from "./Sitemap.css.js";

const path = "/sitemap";

const Sitemap: React.FC = () => {
    const {
        common: {
            ftrTerms,
            ftrPrivacy,
            home,
            products,
            aboutUs,
            news,
            support,
            contactUs,
        },
    } = useContext(Content);
    const { pathname } = useLocation();

    const { loading, error, data } = useQuery<SitemapStaticData>(
        GET_SITEMAP_STATIC_CONTENT,
    );

    const sitemapItems = [
        {
            path: "",
            key: "home",
            text: home,
        },

        {
            path: "/about",
            key: "about",
            text: aboutUs,
        },
        {
            path: "/contacts",
            key: "contacts",
            text: contactUs,
        },

        {
            path: "/news",
            key: "news",
            text: news,
            query: GET_NEWS_FEED_FOR_SITEMAP,
        },
        {
            path: "/apps",
            key: "apps",
            text: products,
            query: GET_PRODUCTS_FEED_FOR_SITEMAP,
        },
        {
            path: "/support",
            key: "support",
            text: support,
            query: GET_PRODUCTS_FEED_FOR_SITEMAP,
        },
        {
            path: "/terms-of-use",
            key: "terms-of-use",
            text: ftrTerms,
        },
        {
            path: "/privacy-policy",
            key: "privacy-policy",
            text: ftrPrivacy,
        },
    ];
    if (error) {
        return <ErrorMsg type={"default"} />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (data) {
        const {
            staticContent: {
                content: { title, metaTitle, metaDescription },
            },
        } = data;

        return (
            <>
                <MetaTags
                    path={path}
                    title={metaTitle}
                    description={metaDescription}
                    openGraphImage={[OPEN_GRAPH_IMAGE_PATH, "1200x630"]}
                />
                <div className={container.flex}>
                    <h1 className={commonPageTitle}>{title}</h1>
                    <div className={list}>
                        {sitemapItems.map(({ path, key, text, query }) => (
                            <div key={key} className={listItem}>
                                <Link
                                    to={path}
                                    onClick={() => {
                                        gaSitemapClick(key, pathname);
                                    }}
                                    className={itemHeader}
                                >
                                    {text}
                                </Link>

                                {query && (
                                    <LinksListLoader
                                        query={query}
                                        rootPath={path}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }
    return <ErrorMsg type={"notFound"} />;
};

export default Sitemap;
