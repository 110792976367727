import React, { useContext } from "react";

// Components:
import { container } from "../styles/common.css.js";
import Content from "../../i18n/content.js";
import { COPYRIGHT_LINK } from "../../config/content.js";
import { COMPANY_FULL_NAME } from "../../../_common/config.js";
import { Link, useLocation } from "react-router-dom";
import { gaNavClick } from "../../helpers/googleAnalytics.js";
import TrustPilotLink from "../common/TrustPilotLink.js";
import { copyright, footer, footerLink, linksContainer } from "./Footer.css.js";

const Footer: React.FC = () => {
    const {
        common: { ftrTerms, ftrPrivacy, ftrSiteMap },
    } = useContext(Content);
    const { pathname } = useLocation();

    return (
        <footer className={footer}>
            <div className={container.flex}>
                <TrustPilotLink location="footer" />
                <div className={linksContainer}>
                    <Link
                        to={`/privacy-policy`}
                        onClick={() => {
                            gaNavClick("privacy-policy", "footer", pathname);
                        }}
                        className={footerLink}
                    >
                        {ftrPrivacy}
                    </Link>
                    <Link
                        to={`/terms-of-use`}
                        onClick={() => {
                            gaNavClick("terms-of-use", "footer", pathname);
                        }}
                        className={footerLink}
                    >
                        {ftrTerms}
                    </Link>
                    <Link
                        to={`/sitemap`}
                        onClick={() => {
                            gaNavClick("sitemap", "footer", pathname);
                        }}
                        className={footerLink}
                    >
                        {ftrSiteMap}
                    </Link>
                </div>
                <div className={copyright}>
                    <a href={COPYRIGHT_LINK} className={footerLink}>
                        {`© ${new Date().getFullYear()} ${COMPANY_FULL_NAME}`}
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
