import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";

import StoreBadge from "./StoreBadge.js";
import { gaConversionLinkClick } from "../../helpers/googleAnalytics.js";
import { makeIconUrl, trackMarketUrl } from "../../helpers/utils.js";
import Content from "../../i18n/content.js";
import { App } from "../../graphql/queries/app.js";
import { container } from "../styles/common.css.js";
import { PromoAppSetterContext } from "../layout/PromoAppContextProvider.js";
import {
    activeTabButton,
    activeTabContent,
    appName,
    blockedBox,
    imgWrapper,
    inactiveTabContent,
    logo,
    logoBox,
    storeButtons,
    storeButtonsTitle,
    storeButtonsWrapper,
    tab,
    tabButton,
    tabContent,
    tabsBox,
} from "./StoreButtons.css.js";

export interface TabsContent {
    title: string;
    iconUrl?: string;
    apps: App[];
    description?: string;
    location: string;
    item: string;
}
export interface Props {
    apps: App[];
    productId: string;
}
const StoreButtons: React.FC<Props> = ({ apps, productId }) => {
    const {
        productPage: { storeVersionsTitle },
    } = useContext(Content);

    const { pathname } = useLocation();

    const [activeIndex, setActiveIndex] = useState<number>(0);

    const [promoApps, setPromoApps] = useState(apps);

    const pickPromoApp = useContext(PromoAppSetterContext);

    useEffect(() => {
        if (promoApps) {
            pickPromoApp({
                type: "pick",
                apps: promoApps,
            });
        }
    }, [promoApps, pickPromoApp]);

    const tabs = apps.reduce<TabsContent[]>((map, app) => {
        const index = map.findIndex(item => item.title === app.title);

        if (index !== -1) {
            map[index].apps.push(app);
        } else {
            const apps = [app];
            const tab: TabsContent = {
                title: app.title,
                iconUrl: app.icon
                    ? makeIconUrl(app.icon.sourceUrl, app.icon.sizes)
                    : undefined,
                apps,
                location: "product",
                item: productId,
            };
            map.push(tab);
        }

        return map;
    }, []);

    return (
        <div className={storeButtonsWrapper}>
            <div className={container.flex800}>
                <h2 className={storeButtonsTitle}>{storeVersionsTitle}</h2>
                <div className={tabsBox}>
                    {tabs.map(
                        ({ title, iconUrl, apps, item, location }, index) => (
                            <div className={tab} key={`${item}${index}`}>
                                {tabs.length > 1 ? (
                                    <button
                                        className={
                                            index === activeIndex
                                                ? activeTabButton
                                                : tabButton
                                        }
                                        onClick={() => {
                                            setActiveIndex(index);
                                            setPromoApps(apps);
                                        }}
                                    >
                                        {title}
                                    </button>
                                ) : null}

                                <div
                                    className={`${tabContent} ${
                                        index === activeIndex
                                            ? activeTabContent
                                            : inactiveTabContent
                                    }`}
                                >
                                    <div className={logoBox}>
                                        <img
                                            src={iconUrl}
                                            alt={title}
                                            className={logo}
                                        />
                                        <p className={appName}>{title}</p>
                                    </div>
                                    <div className={storeButtons}>
                                        {apps.map(
                                            ({ marketUrl, id, platform }) =>
                                                marketUrl ? (
                                                    <a
                                                        key={id}
                                                        href={trackMarketUrl(
                                                            marketUrl,
                                                            platform,
                                                            location,
                                                            item,
                                                        )}
                                                        onClick={() => {
                                                            gaConversionLinkClick(
                                                                platform,
                                                                pathname,
                                                                id,
                                                            );
                                                        }}
                                                        className={imgWrapper}
                                                    >
                                                        <StoreBadge
                                                            platform={platform}
                                                        />
                                                    </a>
                                                ) : (
                                                    <div
                                                        className={imgWrapper}
                                                        key={id}
                                                    >
                                                        <div
                                                            className={
                                                                blockedBox
                                                            }
                                                        >
                                                            Coming soon
                                                        </div>
                                                        <StoreBadge
                                                            platform={platform}
                                                        />
                                                    </div>
                                                ),
                                        )}
                                    </div>
                                </div>
                            </div>
                        ),
                    )}
                </div>
            </div>
        </div>
    );
};

export default StoreButtons;
