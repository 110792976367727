import React from "react";
import MetaTags from "../MetaTags.js";
import ErrorMsg from "../ErrorMsg.js";
import Spinner from "../Spinner.js";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import { GetProductData, GET_PRODUCT } from "../../graphql/queries/products.js";
import { Thumbnail } from "../../graphql/queries/gallery.js";
import {
    findClosestThumbnail,
    makeBackgroundImgUrl,
} from "../../helpers/utils.js";
import Features from "../product/Features.js";
import HowToUse from "../product/HowToUse.js";
import About from "../product/About.js";
import MoreInfo from "../product/MoreInfo.js";
import Assessment from "../product/Assessment.js";
// import ActionsTrigger from "../layout/ActionsTrigger.js";
import Intro from "./Intro.js";
// import StoreButtons from "../product/StoreButtons.js";

const SolarWalkLiteWithSolarTrail: React.FC = () => {
    const productId = "solar-walk-lite";
    const { data, loading, error } = useQuery<GetProductData>(GET_PRODUCT, {
        variables: {
            id: productId,
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <ErrorMsg type={"default"} />;
    }

    if (data && data.product) {
        const path = `/apps/${productId}`;

        const { product } = data;
        const { content } = product;

        const ogImageThumbnail: Thumbnail = findClosestThumbnail(
            product.productCardImage.content.thumbnails,
        );

        const ogImageUrl =
            product.productCardImage.content.sourceUrl +
            ogImageThumbnail.fileName;

        const featuresSlides = product.content.features.map(item => {
            return {
                id: item.image?.content.name,
                description: item.description,
                image: item.image,
            };
        });
        return (
            <>
                <MetaTags
                    path={path}
                    title={content.metaTitle}
                    description={content.metaDescription || content.preview}
                    openGraphImage={[ogImageUrl, ogImageThumbnail.fileName]}
                />
                <Intro
                    backgroundImage={makeBackgroundImgUrl(
                        product.introBackgroundImage,
                    )}
                    title={content.introTitle}
                    subtitle={content.introSlogan}
                    link={{
                        text: "Download",
                        url: "https://solarwalklite.page.link/NTST",
                    }}
                    typeAboutMediaItem={product.aboutMediaItem.__typename}
                    icon={product.icon}
                    productId={product.id}
                />
                {/* <StoreButtons apps={product.apps} productId={product.id} /> */}
                <About
                    text={content.aboutText}
                    mediaItem={product.aboutMediaItem}
                />
                <HowToUse
                    text={content.howToUseText}
                    productName={product.content.name}
                />
                {featuresSlides && featuresSlides.length !== 0 && (
                    <Features featuresSlides={featuresSlides} />
                )}

                <Assessment reviews={content.reviews} awards={content.awards} />
                <MoreInfo
                    relatedNews={content.relatedNews}
                    thirdPartyNews={content.thirdPartyNews}
                    links={content.links}
                    productId={productId}
                />
                {/* <ActionsTrigger position="200vh" /> */}
            </>
        );
    }

    return <ErrorMsg type={"notFound"} />;
};

export default SolarWalkLiteWithSolarTrail;
