import React from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";

import {
    GET_STATIC_CONTENT,
    GetStaticContentData,
    GetStaticContentVariables,
} from "../../graphql/queries/static-content.js";
import ErrorMsg from "../ErrorMsg.js";
import { container } from "../styles/common.css.js";
import Spinner from "../Spinner.js";
import MetaTags from "../MetaTags.js";
import {
    OPEN_GRAPH_IMAGE_PATH,
    // OPEN_GRAPH_TWITTER_IMAGE_PATH,
} from "../../config/content.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import { staticTitle } from "./StaticTextContent.css.js";

interface Props {
    id: string;
    path: string;
}

interface ContentStructure {
    title: string;
    metaTitle: string;
    metaDescription: string;
    _text: string;
}

const StaticTextContent: React.FC<Props> = ({ id, path }) => {
    const { data, loading, error } = useQuery<
        GetStaticContentData<ContentStructure>,
        GetStaticContentVariables
    >(GET_STATIC_CONTENT, {
        variables: {
            id,
        },
    });

    if (error) {
        return <ErrorMsg type={"default"} />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (data && data.staticContent) {
        const {
            content: { _text, title, metaDescription, metaTitle },
        } = data.staticContent;
        return (
            <>
                <MetaTags
                    path={path}
                    title={metaTitle || title}
                    description={metaDescription}
                    openGraphImage={[OPEN_GRAPH_IMAGE_PATH, "1200x630"]}
                    // openGraphTwitterImageURL={OPEN_GRAPH_TWITTER_IMAGE_PATH}
                />
                <div className={container.flex900}>
                    <h1 className={staticTitle}>{title}</h1>
                    <MarkdownRenderer source={_text} />
                </div>
            </>
        );
    }

    return <ErrorMsg type={"notFound"} />;
};

export default StaticTextContent;
