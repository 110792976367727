import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";

import About from "./About.js";
import Features from "./Features.js";
import HowToUse from "./HowToUse.js";
import Intro from "./Intro.js";
import MoreInfo from "./MoreInfo.js";
import Assessment from "./Assessment.js";
import StoreButtons from "./StoreButtons.js";
import ErrorMsg from "../ErrorMsg.js";
import Spinner from "../Spinner.js";
import { GetProductData, GET_PRODUCT } from "../../graphql/queries/products.js";
import MetaTags from "../MetaTags.js";
// import HtmlGoogleStructuredData from "../helmet/HtmlGoogleStructuredData";
import ActionsTrigger from "../layout/ActionsTrigger.js";
import {
    findClosestThumbnail,
    makeBackgroundImgUrl,
} from "../../helpers/utils.js";
import { Thumbnail } from "../../graphql/queries/gallery.js";

const Product: React.FC = () => {
    const { productId } = useParams<{ productId: string }>();

    const { data, loading, error } = useQuery<GetProductData>(GET_PRODUCT, {
        variables: {
            id: productId,
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <ErrorMsg type={"default"} />;
    }
    if (data && data.product) {
        const path = `/apps/${productId}`;

        const { product } = data;
        const { content } = product;

        const ogImageThumbnail: Thumbnail = findClosestThumbnail(
            product.productCardImage.content.thumbnails,
        );

        const ogImageUrl =
            product.productCardImage.content.sourceUrl +
            ogImageThumbnail.fileName;

        const featuresSlides = product.content.features.map(item => {
            return {
                id: item.image?.content.name,
                description: item.description,
                image: item.image,
            };
        });
        // const operatingSystem = Array.from(
        //     new Set(product.apps.map(app => app.platform)).values(),
        // ).join(",");

        return (
            <>
                <MetaTags
                    path={path}
                    title={content.metaTitle}
                    description={content.metaDescription || content.preview}
                    openGraphImage={[ogImageUrl, ogImageThumbnail.fileName]}
                />
                {/* <HtmlGoogleStructuredData
                    data={{
                        type: "App",
                        title: content.introTitle,
                        operatingSystem: operatingSystem,
                        ratingValue: product.ratingValue,
                        ratingCount: product.reviewCount,
                        bestRating: product.bestRating,
                    }}
                /> */}
                <Intro
                    backgroundImage={makeBackgroundImgUrl(
                        product.introBackgroundImage,
                    )}
                    title={content.introTitle}
                    subtitle={content.introSlogan}
                    link={content.introLink}
                    typeAboutMediaItem={product.aboutMediaItem.__typename}
                    icon={product.icon}
                    productId={product.id}
                />
                <StoreButtons apps={product.apps} productId={product.id} />
                <About
                    text={content.aboutText}
                    mediaItem={product.aboutMediaItem}
                />
                <HowToUse
                    text={content.howToUseText}
                    productName={product.content.name}
                />
                {featuresSlides && featuresSlides.length !== 0 && (
                    <Features featuresSlides={featuresSlides} />
                )}

                <Assessment reviews={content.reviews} awards={content.awards} />
                <MoreInfo
                    relatedNews={content.relatedNews}
                    thirdPartyNews={content.thirdPartyNews}
                    links={content.links}
                    productId={productId}
                />
                <ActionsTrigger position="50vh" />
            </>
        );
    }
    return <ErrorMsg type={"notFound"} />;
};

export default Product;
