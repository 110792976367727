import React from "react";
import { Switch, Route } from "react-router";

import Layout from "./layout/Layout.js";
import StaticTextContent from "./static-content/StaticTextContent.js";
import Home from "./home/Home.js";
import Products from "./products/Products.js";
import NewsFeed from "./news/NewsFeed.js";
import News from "./news/News.js";
import Product from "./product/Product.js";
import About from "./about/About.js";
import ErrorMsg from "./ErrorMsg.js";
import Support from "./support/Support.js";
import ProductSupport from "./support/ProductSupport.js";
import ContactUs from "./contacts/Contacts.js";
import Sitemap from "./sitemap/Sitemap.js";
import "./styles/global.css.js";
import SolarWalkLiteWithSolarTrail from "./specials/SolarWalkLiteWithSolarTrail.js";

const AppRoot: React.FC = () => {
    return (
        <Layout>
            <Switch>
                <Route sensitive={true} path={`/`} exact={true} strict={true}>
                    <Home />
                </Route>
                <Route
                    sensitive={true}
                    path={`/apps`}
                    exact={true}
                    strict={true}
                >
                    <Products path={"/apps"} />
                </Route>
                <Route
                    sensitive={true}
                    path={`/news`}
                    exact={true}
                    strict={true}
                >
                    <NewsFeed path={"/news"} />
                </Route>
                <Route
                    sensitive={true}
                    path={`/news/:newsId`}
                    exact={true}
                    strict={true}
                    render={({ match: { params } }) => (
                        <News
                            newsId={params.newsId}
                            path={`/news/${params.newsId}`}
                        />
                    )}
                />
                <Route
                    sensitive={true}
                    path={`/apps/solar-walk-lite-with-solar-trail`}
                    exact={true}
                    strict={true}
                >
                    <SolarWalkLiteWithSolarTrail />
                </Route>
                <Route
                    sensitive={true}
                    path={`/apps/:productId`}
                    exact={true}
                    strict={true}
                >
                    <Product />
                </Route>
                <Route
                    sensitive={true}
                    path={`/apps/:productId/:documentId`}
                    exact={true}
                    strict={true}
                    render={({ match: { params } }) => (
                        <StaticTextContent
                            id={`${params.productId}-${params.documentId}`}
                            path={`/apps/${params.productId}/${params.documentId}`}
                        />
                    )}
                />
                <Route
                    sensitive={true}
                    path={`/about`}
                    exact={true}
                    strict={true}
                >
                    <About path="/about" />
                </Route>
                <Route
                    sensitive={true}
                    path={`/support`}
                    exact={true}
                    strict={true}
                >
                    <Support path="/support" />
                </Route>
                <Route
                    sensitive={true}
                    path={`/support/:productId`}
                    exact={true}
                    strict={true}
                >
                    <ProductSupport />
                </Route>
                <Route
                    sensitive={true}
                    path={`/contacts`}
                    exact={true}
                    strict={true}
                >
                    <ContactUs path="/contacts" />
                </Route>
                <Route
                    sensitive={true}
                    path={`/sitemap`}
                    exact={true}
                    strict={true}
                >
                    <Sitemap />
                </Route>
                <Route
                    sensitive={true}
                    path={`/privacy-policy`}
                    exact={true}
                    strict={true}
                >
                    <StaticTextContent
                        id="vito-privacy-policy"
                        path={"/privacy-policy"}
                    />
                </Route>
                <Route
                    sensitive={true}
                    path={`/terms-of-use`}
                    exact={true}
                    strict={true}
                >
                    <StaticTextContent
                        id="vito-terms-of-use"
                        path={"/terms-of-use"}
                    />
                </Route>
                <Route render={() => <ErrorMsg type={"notFound"} />} />
            </Switch>
        </Layout>
    );
};

export default AppRoot;
