import React, { useContext } from "react";
import { ASSETS_PATH } from "../../config/content.js";
import Content from "../../i18n/content.js";
import { AppPlatform } from "../../graphql/queries/app.js";
import { storeButtonImg } from "./StoreBadge.css.js";

interface Props {
    platform: AppPlatform;
}

const StoreBadge: React.FC<Props> = ({ platform }) => {
    const {
        common: { storeBadgeIos, storeBadgeAndroid, storeBadgeHuawei },
    } = useContext(Content);
    const STORE_BADGES = {
        ios: { iconName: "appstore.svg", alt: storeBadgeIos },
        android: { iconName: "googleplay.svg", alt: storeBadgeAndroid },
        huawei: { iconName: "huaweistore.svg", alt: storeBadgeHuawei },
    };
    return (
        <img
            src={`${ASSETS_PATH}/badges/en/${STORE_BADGES[platform].iconName}`}
            alt={STORE_BADGES[platform].alt}
            className={storeButtonImg}
        />
    );
};

export default StoreBadge;
