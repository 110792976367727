import React, { useContext } from "react";

import Content from "../../i18n/content.js";
import { IMediaItem } from "../../graphql/queries/gallery.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import MediaItem from "../common/MediaItem.js";
import { commonSectionTitle, container } from "../styles/common.css.js";

interface Props {
    text: string;
    mediaItem: IMediaItem | null;
}
const About: React.FC<Props> = ({ text, mediaItem }) => {
    const {
        productPage: { aboutTitle },
    } = useContext(Content);
    return (
        <div className={container.flex800}>
            <div>
                <h2 className={commonSectionTitle}>{aboutTitle}</h2>
                <MarkdownRenderer source={text} />
                <MediaItem
                    mediaItem={mediaItem}
                    srcSetSizes={{
                        desktop: 770,
                        laptop: 770,
                        tablet: 538,
                    }}
                />
            </div>
        </div>
    );
};

export default About;
