import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ClientCookies from "js-cookie";

import Content from "../../i18n/content.js";
import {
    isClient,
    setLocalStorageItem,
    getLocalStorageItem,
} from "../../helpers/utils.js";
import { gaCookieConsent } from "../../helpers/googleAnalytics.js";
import { button, contentBlock, textBlock } from "./CookieConsent.css.js";

const ACCEPT_COOKIE_NAME = "SWCOOKIESACC";

const CookieConsent: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    const {
        common: { cookieText, cookieLearnMore, cookieAcptBtn },
    } = useContext(Content);
    const { pathname } = useLocation();

    useEffect(() => {
        if (
            ClientCookies.get(ACCEPT_COOKIE_NAME) !== "1" &&
            getLocalStorageItem(ACCEPT_COOKIE_NAME) !== "1"
        ) {
            setIsVisible(true);
        }
    }, []);

    if (!isClient()) {
        return null;
    }

    if (isVisible) {
        const onClickHandler = () => {
            ClientCookies.set(ACCEPT_COOKIE_NAME, "1", {
                secure: true,
                sameSite: "strict",
                expires: new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000),
            });
            setIsVisible(false);
            gaCookieConsent("accept", pathname);
            setLocalStorageItem(ACCEPT_COOKIE_NAME, "1");
        };

        return (
            <div className={contentBlock}>
                <div className={textBlock}>
                    {cookieText + " "}
                    <Link
                        to={`/privacy-policy`}
                        onClick={() => {
                            gaCookieConsent("learn_more", pathname);
                        }}
                    >
                        {cookieLearnMore}
                    </Link>
                </div>
                <button className={button} onClick={onClickHandler}>
                    {cookieAcptBtn}
                </button>
            </div>
        );
    }

    return null;
};

export default CookieConsent;
