import React from "react";
import { FieldRenderProps } from "react-final-form";
import { errorMessage, optionItem, selectField } from "./formComponents.css.js";

type Props = FieldRenderProps<string, any>;

const SelectInput: React.FC<Props> = ({
    optionsData,
    input,
    meta,
    ...rest
}: Props) => {
    const optionsDataArray: string[][] = Object.entries(optionsData);

    return (
        <>
            <select className={selectField} {...input} {...rest}>
                <option value="" disabled />

                {optionsDataArray.map((item: string[], index: number) => {
                    return (
                        <option
                            className={optionItem}
                            key={index}
                            value={item[0]}
                        >
                            {item[1]}
                        </option>
                    );
                })}
            </select>
            {meta.error && meta.touched && (
                <span className={errorMessage}>{meta.error}</span>
            )}
        </>
    );
};

export default SelectInput;
