import { useMutation } from "@apollo/client/react/hooks/useMutation.js";
import React, { useContext } from "react";
import { Form, Field } from "react-final-form";

import { VITO_APP_ID } from "../../../_common/config.js";
import { CLOSE } from "../../config/icons.js";
import {
    CREATE_SUPPORT_MESSAGE,
    SendMessageInput,
    SendMessagePayload,
} from "../../graphql/queries/support.js";
import Content from "../../i18n/content.js";
import { AppPlatform } from "../../graphql/queries/app.js";
import CircleSpinner from "../common/CircleSpinner.js";
import Icon from "../svg/Icon.js";
import Captcha from "./Captcha.js";
import SelectInput from "./formComponents/SelectInput.js";
import TextAreaInput from "./formComponents/TextAreaInput.js";
import TextInput from "./formComponents/TextInput.js";
import {
    closeButton,
    closeIcon,
    dangerMessageText,
    formContent,
    formRow,
    formTitleStyle,
    formWrapper,
    messageButton,
    messageText,
    popup,
    popupMessage,
    sendButton,
    titleBox,
} from "./SupportForm.css.js";

interface Props {
    productId: string;
    onClose: () => void;
}
interface Values {
    name: string;
    email: string;
    platform: AppPlatform;
    message: string;
}
const platformSelectOptions = {
    ios: "iOS",
    android: "Android",
};
const SupportForm: React.FC<Props> = ({ onClose, productId }) => {
    const {
        productSupportPage: {
            formTitle,
            nameInputLbl,
            nameInputPlaceholder,
            emailInputLbl,
            emailInputPlaceholder,
            platformSelectLbl,
            messageInputLbl,
            errMsgText,
            inputReqText,
            emailInvalidText,
            captchaInvalidText,
            submitBtn,
            popupMsgText,
            okBtn,
        },
    } = useContext(Content);
    const required = (value: string) =>
        value && value.trim() ? undefined : inputReqText;

    const isEmailValid = (value: string) => {
        var regex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        return regex.test(value);
    };
    const emailValidator = (value: string) => {
        if (isEmailValid(value)) {
            return undefined;
        }
        return emailInvalidText;
    };
    const captchaValidator = (value: boolean) => {
        return value === true ? undefined : captchaInvalidText;
    };

    const [sendMessage, { loading }] = useMutation<
        { sendMessage: SendMessagePayload },
        { input: SendMessageInput }
    >(CREATE_SUPPORT_MESSAGE);

    const onSubmit = async (value: Values) => {
        const dataToSave: SendMessageInput = {
            to: "Support",
            source: VITO_APP_ID,
            productInfo: {
                productId: productId,
                platform: value.platform,
            },
            email: value.email,
            name: value.name.trim(),
            message: value.message.trim(),
        };

        try {
            await sendMessage({
                variables: { input: dataToSave },
            });
        } catch (e) {
            return { "FINAL_FORM/form-error": "ERROR" };
        }
    };
    return (
        <Form
            onSubmit={onSubmit}
            render={({
                handleSubmit,
                submitSucceeded,
                submitting,
                hasSubmitErrors,
                validating,
            }) => (
                <div className={formWrapper}>
                    <div className={titleBox}>
                        <h3 className={formTitleStyle}>{formTitle}</h3>
                        <button className={closeButton} onClick={onClose}>
                            <Icon path={CLOSE} className={closeIcon} />
                        </button>
                    </div>

                    <form className={formContent}>
                        <div className={formRow}>
                            <label>{`${nameInputLbl} *`}</label>
                            <Field
                                name="name"
                                component={TextInput}
                                placeholder={nameInputPlaceholder}
                                validate={required}
                            />
                        </div>
                        <div className={formRow}>
                            <label>{`${emailInputLbl} *`}</label>
                            <Field
                                name="email"
                                component={TextInput}
                                placeholder={emailInputPlaceholder}
                                validate={emailValidator}
                            />
                        </div>
                        <div className={formRow}>
                            <label>{`${platformSelectLbl} *`}</label>
                            <Field
                                name="platform"
                                component={SelectInput}
                                optionsData={platformSelectOptions}
                                validate={required}
                            />
                        </div>
                        <div className={formRow}>
                            <label>{`${messageInputLbl} *`}</label>
                            <Field
                                name="message"
                                component={TextAreaInput}
                                validate={required}
                            />
                        </div>
                        <Field
                            name="captcha"
                            component={Captcha}
                            validate={captchaValidator}
                        />
                        <button
                            className={sendButton}
                            type="button"
                            onClick={() => {
                                handleSubmit();
                            }}
                            disabled={submitting || validating}
                        >
                            {loading ? (
                                <CircleSpinner />
                            ) : (
                                <span>{submitBtn}</span>
                            )}
                        </button>
                        {hasSubmitErrors ? (
                            <p
                                className={
                                    hasSubmitErrors
                                        ? dangerMessageText
                                        : messageText
                                }
                            >
                                {errMsgText}
                            </p>
                        ) : null}
                    </form>
                    {submitSucceeded ? (
                        <div className={popup}>
                            <div className={popupMessage}>
                                <p className={messageText}>{popupMsgText}</p>
                                <button
                                    onClick={onClose}
                                    className={messageButton}
                                >
                                    {okBtn}
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
            )}
        />
    );
};

export default SupportForm;
