import React from "react";
import ReactMarkdown from "react-markdown";
import { IMediaItem } from "../../graphql/queries/gallery.js";
import ImageItem from "../common/ImageItem.js";
import {
    infoDate,
    infoText,
    infoTitle,
    timelineWrapper,
} from "./Timeline.css.js";

interface Props {
    timelineData: string;
    mediaItems: IMediaItem[];
}
const Timeline: React.FC<Props> = ({ timelineData, mediaItems }) => {
    return (
        <div className={timelineWrapper}>
            <ReactMarkdown
                children={timelineData}
                components={{
                    h3: props => <h3 className={infoDate} {...props} />,
                    h4: props => <h4 className={infoTitle} {...props} />,
                    p: props => <p className={infoText} {...props} />,
                    img: props => {
                        const image = mediaItems.find(
                            item => item.id === props.src,
                        );

                        return image?.__typename === "Image" ? (
                            <ImageItem
                                image={image}
                                noAspectWrapper
                                srcSetSizes={{
                                    desktop: 540,
                                    laptop: 540,
                                    tablet: 438,
                                }}
                            />
                        ) : null;
                    },
                }}
            />
        </div>
    );
};

export default Timeline;
