import type { FieldPolicy } from "@apollo/client/core/index.js";
import { FeedConnection } from "./queries/_common";
import { NewsForFeed } from "./queries/news";

const feedFieldPolicy = (
    keyArgs: string[] | false = false,
): FieldPolicy<FeedConnection<NewsForFeed>> => {
    return {
        keyArgs: keyArgs,
        merge: (existing, incoming) => ({
            ...existing,
            ...incoming,
            edges: [...(existing?.edges || []), ...incoming.edges],
        }),
    };
};
export const typePolicies = {
    Query: {
        fields: {
            companyNewsFeed: feedFieldPolicy(["first"]),
        },
    },
};
