import React from "react";
import { GetCommonContentData } from "../graphql/queries/common-content.js";
import { ImagePreview, Image } from "../graphql/queries/gallery.js";
import { LangCode } from "../graphql/queries/_common.js";

export interface LocalizedContent {
    langCode: LangCode;
    common: CommonContent;
    productsPage: ProductsPageContent;
    productPage: ProductPageContent;
    newsFeedPage: NewsFeedPageContent;
    newsPage: NewsPageContent;
    supportPage: SupportPageContent;
    productSupportPage: ProductSupportPageContent;
}
export interface MenuItemsMap<T> {
    [key: string]: T;
    home: T;
    apps: T;
    news: T;
    about: T;
    support: T;
    contacts: T;
}

export interface CommonContent {
    home: string;
    logo: string;
    news: string;
    products: string;
    aboutUs: string;
    support: string;
    contactUs: string;
    ftrFollowUs: string;
    ftrPrivacy: string;
    ftrSignUp: string;
    ftrSubscribe: string;
    ftrTerms: string;
    ftrSiteMap: string;
    cookieText: string;
    cookieLearnMore: string;
    cookieAcptBtn: string;
    errDefTitle: string;
    errDefMsg: string;
    errNotFoundTitle: string;
    errNotFoundMsg: string;
    storeBadgeIos: string;
    storeBadgeAndroid: string;
    storeBadgeHuawei: string;
    getAppButton: string;
    downloadAppButton: string;
}

export interface Links {
    text: string;
    url: string;
}

export interface HomePageContent {
    title: string;
    description: string;
    metaTitle: string;
    metaDescription: string;
    downloadAppBtn: string;
    downloadAppBtnLink: string;
    aboutUsBtn: string;
    allAppsTitle: string;
    allAppsBtn: string;
    allAppsReadMoreBtn: string;
    latestNewsTitle: string;
    moreNewsButton: string;
    partnersSecTitle: string;
}

export interface Product {
    featured: boolean;
    image: Image | ImagePreview | null;
    title: string;
    description: string;
}
export interface NewsItem {
    id: string;
    featured: boolean;
    urlImg: string;
    imgCredit: string;
    alt: string;
    date: string;
    title: string;
    description: string;
}
export interface ProductsPageContent {
    metaTitle: string;
    metaDescription: string;
    title: string;
    subtitle: string;
    appButton: string;
}
export interface SupportPageContent {
    metaTitle: string;
    metaDescription: string;
    title: string;
    description: string;
    subtitle: string;
}
export interface ProductSupportPageContent {
    title: string;
    metaDescription: string;
    faqTitle: string;
    faqSubtitle: string;
    emptyFaqMsg: string;
    contactTitle: string;
    contactBtn: string;
    formTitle: string;
    nameInputLbl: string;
    nameInputPlaceholder: string;
    emailInputLbl: string;
    emailInputPlaceholder: string;
    platformSelectLbl: string;
    messageInputLbl: string;
    messageInputPlaceholder: string;
    filesInputLbl: string;
    filesInputPlaceholder: string;
    errMsgText: string;
    inputReqText: string;
    emailInvalidText: string;
    captchaInvalidText: string;
    submitBtn: string;
    cancelBtn: string;
    popupMsgText: string;
    okBtn: string;
}
export interface NewsPageContent {
    imageCredit: string;
    textCredit: string;
    backToNewsFeed: string;
}
export interface NewsFeedPageContent {
    title: string;
    metaTitle: string;
    metaDescription: string;
    loadMoreButton: string;
    readMoreButton: string;
}

export interface Awards {
    link: string;
    logoSrc: string;
    alt: string;
}
export interface Review {
    quote: string;
    author: string;
}
export interface News {
    urlImg: string;
    urlArticle: string;
    date: string;
    subtitle: string;
}
export interface Recources {
    date: string;
    subtitle: string;
    urlArticle: string;
}
export interface AppStoreButton {
    urlImg: string;
    urlLink: string;
}
export interface TabContent {
    tabButton: string;
    appLogoUrl: string;
    appLogoAlt: string;
    appName: string;
    appStoreButtons: AppStoreButton[];
}
export interface Button {
    textButton: string;
    urlButton: string;
}
export interface ProductPageContent {
    aboutTitle: string;
    reviewsTitle: string;
    featuresTitle: string;
    howToUseTitle: string;
    videoBtn: string;
    downloadBtn: string;
    newsMentionsTitle: string;
    resourcesTitle: string;
    moreInfoTitle: string;
    storeVersionsTitle: string;
}

export interface ContactsPageContent {
    title: string;
    metaTitle: string;
    metaDescription: string;
    _text: string;
    locationTitle: string;
    socialTitle: string;
    _addressOne: string;
    _addressTwo: string;
}

export const buildContent = (
    langCode: LangCode,
    {
        common,
        productsPage,
        productPage,
        newsFeedPage,
        newsPage,
        supportPage,
        productSupportPage,
    }: GetCommonContentData,
): LocalizedContent => {
    return {
        langCode: langCode,
        common: common.content,
        productPage: productPage.content,
        productsPage: productsPage.content,
        newsFeedPage: newsFeedPage.content,
        newsPage: newsPage.content,
        supportPage: supportPage.content,
        productSupportPage: productSupportPage.content,
    };
};

const Content = React.createContext<LocalizedContent>(undefined!);
export default Content;
