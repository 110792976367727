import gql from "graphql-tag";

import { IMediaItem, Image } from "./gallery.js";
import { FeedConnection, LangCode } from "./_common.js";
import { App, AppIcon } from "./app.js";

export interface GetProductVariables {
    id: string;
}
export interface Product<C, A = undefined, M = undefined, I = undefined> {
    id: string;
    ratingValue: string;
    reviewCount: string;
    bestRating: string;
    introBackgroundImage: I;
    featured: boolean;
    productCardImage: I;
    contentLangs: LangCode[];
    aboutMediaItem: M;
    content: C;
    apps: A[];
    icon: AppIcon | null;
}

export interface ProductContent {
    metaTitle: string;
    metaDescription: string;
    name: string;
    preview: string;
    introTitle: string;
    introSlogan: string;
    introLink: Link;
    aboutText: string;
    howToUseText: string;
    reviews: ReviewSlideContent[];
    awards: AwardContent[];
    relatedNews: RelatedNews[];
    thirdPartyNews: ThirdPartyNews[];
    links: Link[];
    faq: Faq[];
    features: ProductFeature[];
}

export interface AwardContent {
    url: string | null;
    imgUrl: string;
    alt: string;
}
export interface Faq {
    question: string;
    answer: string;
}
export interface Link {
    url: string;
    text: string;
}
export interface ReviewSlideContent {
    quote: string;
    author: string;
    url: string | null;
}
export interface RelatedNews {
    id: string;
    date: string;
    title: string;
    mediaItem: {
        sourceUrl: string;
    };
}
export interface ThirdPartyNews {
    id: string;
    date: string;
    title: string;
    url: string;
}
export interface Card {
    sourceUrl: string;
}
export interface ProductFeature {
    description: string;
    image: Image | null;
}
export type ProductItem = Product<ProductContent, App, IMediaItem, Image>;

export interface GetProductData {
    product: ProductItem | null;
}

export const GET_PRODUCT = gql`
    query getProduct($id: ProductID!) {
        product(id: $id) {
            id
            ratingValue
            reviewCount
            bestRating
            featured
            icon {
                id
                sizes
                sourceUrl
            }
            introBackgroundImage {
                id
                content {
                    sourceUrl
                    thumbnails {
                        fileName
                        width
                        height
                    }
                }
            }
            productCardImage {
                id
                content {
                    sourceUrl
                    thumbnails {
                        fileName
                        width
                        height
                    }
                }
            }
            apps {
                id
                title
                icon {
                    sourceUrl
                    sizes
                }
                marketUrl
                marketId
                platform
            }
            aboutMediaItem {
                id
                __typename
                content {
                    name
                    description
                    copyrights {
                        author
                        url
                    }
                    sourceUrl
                    ... on ImageContent {
                        thumbnails {
                            fileName
                            width
                            height
                        }
                    }
                }
            }
            content {
                metaTitle
                name
                introTitle
                preview
                introSlogan
                introLink {
                    url
                    text
                }
                aboutText
                howToUseText
                reviews {
                    quote
                    author
                    url
                }
                awards {
                    imgUrl
                    alt
                    url
                }
                links {
                    text
                    url
                }
                features {
                    description
                    image {
                        content {
                            sourceUrl
                            description
                            name
                            thumbnails {
                                fileName
                                width
                                height
                            }
                        }
                    }
                }
            }
        }
    }
`;

type ProductContentForFeed = Pick<
    ProductContent,
    "name" | "aboutText" | "preview"
>;

export type ProductsForFeed = Pick<
    Product<ProductContentForFeed, App, Image, Image>,
    "id" | "content" | "featured" | "productCardImage"
>;
export interface GetProductsFeedData {
    products: FeedConnection<ProductsForFeed> | null;
}
export const GET_PRODUCTS = gql`
    query getProducts {
        products {
            totalCount
            offset
            edges {
                node {
                    id
                    featured
                    productCardImage {
                        id
                        content {
                            name
                            sourceUrl
                            thumbnails {
                                fileName
                                width
                                height
                            }
                        }
                    }
                    content {
                        preview
                        name
                        aboutText
                    }
                }
            }
        }
    }
`;

type ProductContentForSupportPage = Pick<ProductContent, "name" | "faq">;
type ProductItemForSupportPage = Pick<
    Product<ProductContentForSupportPage, App, IMediaItem, Image>,
    "id" | "content" | "introBackgroundImage" | "productCardImage"
>;
export interface GetProductForSupportPageData {
    product: ProductItemForSupportPage | null;
}

export const GET_PRODUCT_FOR_SUPPORT_PAGE = gql`
    query getProductForSupportPage($id: ProductID!) {
        product(id: $id) {
            id
            introBackgroundImage {
                content {
                    sourceUrl
                    thumbnails {
                        fileName
                        width
                    }
                }
            }
            productCardImage {
                content {
                    sourceUrl
                    thumbnails {
                        fileName
                        width
                        height
                    }
                }
            }
            content {
                name
                faq {
                    question
                    answer
                }
            }
        }
    }
`;

type ProductsForSupportPage = Pick<
    Product<ProductContentForSupportPage>,
    "id" | "content" | "icon"
>;
export interface GetProductsForSupportPageData {
    products: FeedConnection<ProductsForSupportPage> | null;
}
export const GET_PRODUCTS_FOR_SUPPORT_PAGE = gql`
    query getProductsForSupportPage {
        products {
            edges {
                node {
                    id
                    content {
                        name
                    }
                    icon {
                        sourceUrl
                    }
                }
            }
        }
    }
`;
